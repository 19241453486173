<div class="w-100 app-container">
  <div class="header">
    <mat-toolbar color="primary">
      <div class="ml-2">
        <span>
          {{ appname }}
        </span>
      </div>
      <span class="spacer"></span>

      <ng-container *ngIf="!user?.uid; else logoutview">
        <button routerLink="login" mat-button color="accent">Login</button>
      </ng-container>
      <ng-template #logoutview>
        <button (click)="onLogout()" mat-button color="accent">Logout</button>
      </ng-template>
    </mat-toolbar>
  </div>
  <div class="main-scrollviewport">
    <div
      class="d-flex justify-content-center align-items-center language-translate"
    >
      <div>Choose a Language</div>
      <div class="text-center" id="google_translate_element"></div>
    </div>
    <router-outlet></router-outlet>
  </div>
  <!-- Footer-->
  <tt-footer
    class="footer p-3 text-center"
    style="background-color: #673ab7 !important"
  ></tt-footer>
</div>
