import { Component, OnInit } from '@angular/core';
import { FirebaseUISignInSuccessWithAuthResult } from 'firebaseui-angular';
import { AuthService } from 'src/app/authentication/services/auth.service';
import { CommonUtil } from 'src/app/shared/common.util';
import { UserService } from 'src/app/shared/services/user.service';

@Component({
  selector: 'tt-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  constructor(
    private authService: AuthService,
    private commonUtil: CommonUtil,
    private userService: UserService
  ) {}
  user: any;
  ngOnInit(): void {
    this.authService.user$.subscribe((user) => (this.user = user));
  }
  async successCallback(
    signInSuccessData: FirebaseUISignInSuccessWithAuthResult
  ) {
    let userInfo = signInSuccessData.authResult.user;
    if (userInfo) {
      let user = this.commonUtil.getUserInformation(userInfo);
      await this.userService.saveUser(user);
    }
  }
}
