import {
  BreakpointObserver,
  Breakpoints,
  BreakpointState,
} from '@angular/cdk/layout';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { User } from './models/user';

@Injectable()
export class CommonUtil {
  constructor(private breakpointObserver: BreakpointObserver) {}

  getUserInformation(userInfo: any): User {
    return (
      (userInfo && {
        id: userInfo.uid,
        uid: userInfo.uid,
        displayName: userInfo.displayName || '',
        email: userInfo.email || '',
        emailVerified: userInfo.emailVerified,
        photoURL: userInfo.photoURL || '',
        phoneNumber: userInfo.phoneNumber || '',
        creationTime: userInfo.metadata.creationTime,
        lastSignInTime: userInfo.metadata.lastSignInTime,
      }) ||
      {}
    );
  }

  groupBy(xs, key) {
    return xs.reduce(function (rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  }

  public isHandset$: Observable<boolean> = this.breakpointObserver
    .observe(Breakpoints.Handset)
    .pipe(map((result: BreakpointState) => result.matches));
}
