import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ClaimService {
  constructor(
    private firestore: AngularFirestore,
    private storage: AngularFireStorage,
    private http: HttpClient
  ) {}

  getOwnerDataByUserId(uid: string, admin: boolean = false): Observable<any[]> {
    const path = `${environment?.path}/claims`;
    if (admin) {
      return this.firestore.collection(path).valueChanges();
    } else {
      return this.firestore
        .collection(path, (ref) =>
          ref.where('uid', '==', uid).where('status', '!=', 'deleted')
        )
        .valueChanges();
    }
  }
  async createOwnerData(formData: any) {
    if (!formData?.flatNumber) {
      throw new Error('Flat number is required.');
    }
    try {
      const response = await this.http
        .post<{ message: string; id: string }>(
          `${environment.baseUrl}/claims`,
          formData
        )
        .toPromise();
      if (response) {
        return response;
      }
    } catch (error) {
      console.error('Error creating owner data:', error);
      throw new Error('Failed to create owner data via API.');
    }
  }
  async updateOwnerData(formData: any) {
    if (!formData?.id) {
      console.warn('Form data has no ID, update skipped.');
      return;
    }
    const path = `${environment?.path}/claims/${formData.id}`;
    await this.firestore
      .collection(`${environment?.path}/claims`)
      .doc(formData.id)
      .set(formData, { merge: true });
  }

  getOwnerDataById(docId: string): Observable<any> {
    const path = `${environment?.path}/claims/${docId}`;
    return this.firestore.doc(path).valueChanges();
  }
  async uploadSignImage(folderPath: string, base64Image: string) {
    const byteString = atob(base64Image.split(',')[1]);
    const mimeType = 'image/png';
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const intArray = new Uint8Array(arrayBuffer);

    for (let i = 0; i < byteString.length; i++) {
      intArray[i] = byteString.charCodeAt(i);
    }

    const blob = new Blob([arrayBuffer], { type: mimeType });

    const filenameWithExtension = `signed-image-${Date.now()}.png`;
    const filePath = `${folderPath}/${filenameWithExtension}`;
    const fileRef = this.storage.ref(filePath);
    const task = this.storage.upload(filePath, blob);

    return new Promise((resolve, reject) => {
      task.snapshotChanges().subscribe({
        next: (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        },
        error: (error) => {
          console.error('Upload failed:', error);
          reject(error);
        },
        complete: async () => {
          try {
            const downloadURL = await fileRef.getDownloadURL().toPromise();
            resolve(downloadURL);
          } catch (error) {
            console.error('Error getting download URL:', error);
            reject(error);
          }
        },
      });
    });
  }

  async uploadSignedDocument(folderPath: string, file: File) {
    const fileExtension = file.name.split('.').pop();
    const filenameWithExtension = `${file.name.split('.')[0]}.${fileExtension}`;
    const filePath = `${folderPath}/${filenameWithExtension}`;
    const fileRef = this.storage.ref(filePath);
    const task = this.storage.upload(filePath, file);
    return new Promise(async (resolve, reject) => {
      task.snapshotChanges().subscribe({
        next: async (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        },
        error: (error) => {
          console.error('Upload failed:', error);
          reject(error);
        },
        complete: async () => {
          const downloadURL = await fileRef.getDownloadURL().toPromise();
          resolve(downloadURL);
        },
      });
    });
  }

  async uploadAadharFile(folderPath: string, aadharFile: File) {
    const fileExtension = aadharFile.name.split('.').pop();
    const filenameWithExtension = `${
      aadharFile.name.split('.')[0]
    }.${fileExtension}`;
    const filePath = `${folderPath}/${filenameWithExtension}`;
    const fileRef = this.storage.ref(filePath);
    const task = this.storage.upload(filePath, aadharFile);

    return new Promise(async (resolve, reject) => {
      task.snapshotChanges().subscribe({
        next: async (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        },
        error: (error) => {
          console.error('Upload failed:', error);
          reject(error);
        },
        complete: async () => {
          const downloadURL = await fileRef.getDownloadURL().toPromise();
          resolve(downloadURL);
        },
      });
    });
  }

  deleteClaim(claimId: string) {
    const path = `${environment?.path}/claims/${claimId}`;
    return this.firestore.doc(path).set({ status: 'deleted' }, { merge: true });
  }
}
