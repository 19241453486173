<div class="container">
  <div class="row justify-content-center align-items-center">
    <div class="col-12" *ngIf="!img">
      <div class="mt-5">
        <canvas
          #sigPad
          width="300"
          height="150"
          (mousedown)="onMouseDown($event)"
          (mousemove)="onMouseMove($event)"
        ></canvas>
      </div>
    </div>

    <div class="mt-2">
      <button
        *ngIf="!img"
        class="mx-2"
        mat-flat-button
        color="accent"
        (click)="clear()"
      >
        Clear
      </button>

      <button
        *ngIf="!img"
        class="mx-2"
        mat-flat-button
        color="primary"
        (click)="save()"
      >
        Sign Documents
      </button>
    </div>
  </div>
</div>
