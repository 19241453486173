<div class="container" *ngIf="data.length > 0; else noClaims">
  <div class="row">
    <div class="col-12 col-md-6" *ngFor="let claim of data">
      <mat-card class="m-1 p-1">
        <div class="m-1 p-1">
          <mat-card-header>
            <mat-card-title
              >{{ claim.ownerName }} | {{ claim.id }}</mat-card-title
            >
            <mat-card-subtitle
              >Flat Number: {{ claim.flatNumber }}</mat-card-subtitle
            >
            <div class="spacer"></div>
            <!-- Three-dot icon for actions menu -->
            <button mat-icon-button [matMenuTriggerFor]="menu">
              <mat-icon>more_vert</mat-icon>
            </button>
            <!-- Menu for View, Approve, Hold -->
            <mat-menu #menu="matMenu">
              <button mat-menu-item (click)="onViewClick(claim)">
                <mat-icon>visibility</mat-icon>
                <span>View</span>
              </button>
              <button
                *ngIf="user?.admin"
                mat-menu-item
                (click)="onApproveClick(claim)"
              >
                <mat-icon>check_circle</mat-icon>
                <span>Approve</span>
              </button>
              <button
                *ngIf="user?.admin"
                mat-menu-item
                (click)="onHoldClick(claim)"
              >
                <mat-icon>pause_circle_filled</mat-icon>
                <span>Hold</span>
              </button>
            </mat-menu>
          </mat-card-header>

          <mat-card-content>
            <mat-card-subtitle
              >Email Address: {{ claim.email }}</mat-card-subtitle
            >
            <mat-card-subtitle
              >Mobile: {{ claim.mobileNumber }}</mat-card-subtitle
            >
            <mat-card-subtitle
              >Account Holder Name:
              {{ claim.accountHolderName }}</mat-card-subtitle
            >
            <mat-card-subtitle
              >Status: {{ claim.status | titlecase }}</mat-card-subtitle
            >
          </mat-card-content>
          <mat-card-actions class="justify-content-around">
            <button
              mat-flat-button
              color="primary"
              (click)="onViewClick(claim)"
            >
              View Details
            </button>
            <button
              *ngIf="
                !eSignEnabled &&
                claim?.status !== 'pending' &&
                claim?.status !== 'deleted' &&
                claim?.status !== 'requested' &&
                claim?.status !== 'completed'
              "
              mat-flat-button
              color="accent"
              (click)="onDownload(claim)"
            >
              Download Forms
            </button>
            <button
              *ngIf="
                eSignEnabled &&
                claim?.status !== 'pending' &&
                claim?.status !== 'deleted' &&
                claim?.status !== 'requested' &&
                claim?.status !== 'completed'
              "
              mat-flat-button
              color="warn"
              (click)="onViewClick(claim)"
            >
              eSign
            </button>
            <button
              *ngIf="
                !eSignEnabled &&
                claim?.status !== 'pending' &&
                claim?.status !== 'deleted' &&
                claim?.status !== 'requested' &&
                claim?.status !== 'completed'
              "
              mat-flat-button
              color="warn"
              (click)="onViewClick(claim)"
            >
              Upload
            </button>
          </mat-card-actions>
        </div>
        <div *ngIf="errorMessage" class="error-message text-center">
          {{ errorMessage }}
        </div>
      </mat-card>
    </div>
  </div>
</div>

<ng-template #noClaims>
  <mat-card class="no-claims-card">
    <mat-card-content>
      <p>No details submitted.</p>
    </mat-card-content>
  </mat-card>
</ng-template>
