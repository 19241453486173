import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { CommonUtil } from 'src/app/shared/common.util';
import { User } from 'src/app/shared/models/user';
import { UserService } from 'src/app/shared/services/user.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private _user = new BehaviorSubject<User>({});
  readonly user$ = this._user.asObservable();
  get user() {
    return this._user.getValue();
  }

  constructor(
    private afAuth: AngularFireAuth,
    public router: Router,
    private userService: UserService,
    private commonUtil: CommonUtil
  ) {
    this.afAuth.user.subscribe((userInfo) => {
      if (userInfo) {
        let user = this.commonUtil.getUserInformation(userInfo);
        this.userService.checkUserRole(user.uid).then((roleinfo: any) => {
          user.admin = false;
          if (roleinfo && roleinfo.role === 'admin') {
            user.admin = true;
          }
          this._user.next(user);
          this.router.navigate(['home']);
        });
      } else {
        this._user.next({});
        this.router.navigate(['login']);
      }
    });
  }
  logout() {
    this.afAuth.signOut().then(() => {
      this._user.next({});
      this.router.navigate(['login']);
    });
  }
}
