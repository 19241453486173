import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AuthService } from 'src/app/authentication/services/auth.service';
import { ClaimService } from 'src/app/services/claim.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-view-docs',
  templateUrl: './view-docs.component.html',
  styleUrls: ['./view-docs.component.scss'],
})
export class ViewDocsComponent implements OnInit {
  constructor(private authUser: AuthService) {
    this.authUser.user$.subscribe((user) => (this.user = user));
  }

  user: any;

  ngOnInit(): void {}
  signedImg: any;
  @Input()
  mode: string;
  @Input()
  claim: any = {};
  signSubmitted(img: any) {
    this.signedImg = img;
    this.signSubmittedBtnClicked?.emit(img);
  }
  @Output()
  signSubmittedBtnClicked = new EventEmitter();
}
