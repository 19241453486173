<div>
  <div class="row">
    <div class="col-12">
      Disclaimer: Language translation features on this website are powered by
      Google. The accuracy of translations has not been reviewed by the
      association.
    </div>
    <div class="col-12">
      <span>Copyright &copy; {{ year }} {{ copyright }}.</span>
    </div>
  </div>
</div>
