import {
  Component,
  Input,
  ViewChild,
  ElementRef,
  AfterViewInit,
  HostListener,
  EventEmitter,
  Output,
} from '@angular/core';

@Component({
  selector: 'app-sign-in-pad',
  templateUrl: './sign-in-pad.component.html',
  styleUrls: ['./sign-in-pad.component.scss'],
})
export class SignInPadComponent implements AfterViewInit {
  @Input() name: string;

  @Output()
  signSubmitted = new EventEmitter();

  @ViewChild('sigPad') sigPad: ElementRef<HTMLCanvasElement>;

  sigPadElement: HTMLCanvasElement;
  context: CanvasRenderingContext2D;
  isDrawing = false;
  img: string;

  ngAfterViewInit() {
    this.sigPadElement = this.sigPad.nativeElement;
    this.context = this.sigPadElement.getContext('2d');
    this.context.strokeStyle = '#3742fa';
  }

  // Mouse events
  @HostListener('document:mouseup', ['$event'])
  onMouseUp(e: MouseEvent) {
    this.isDrawing = false;
  }

  onMouseDown(e: MouseEvent) {
    this.isDrawing = true;
    const coords = this.relativeCoords(e);
    this.context.moveTo(coords.x, coords.y);
  }

  onMouseMove(e: MouseEvent) {
    if (this.isDrawing) {
      const coords = this.relativeCoords(e);
      this.context.lineTo(coords.x, coords.y);
      this.context.stroke();
    }
  }

  // Touch events
  @HostListener('touchstart', ['$event'])
  onTouchStart(e: TouchEvent) {
    if (this.isTouchOnCanvas(e)) {
      this.isDrawing = true;
      const coords = this.relativeCoordsTouch(e);
      this.context.moveTo(coords.x, coords.y);
      e.preventDefault(); // Prevents the default scroll behavior on mobile for drawing
    }
  }

  @HostListener('touchmove', ['$event'])
  onTouchMove(e: TouchEvent) {
    if (this.isDrawing && this.isTouchOnCanvas(e)) {
      const coords = this.relativeCoordsTouch(e);
      this.context.lineTo(coords.x, coords.y);
      this.context.stroke();
      e.preventDefault(); // Prevents the default scroll behavior on mobile for drawing
    }
  }

  @HostListener('touchend', ['$event'])
  onTouchEnd(e: TouchEvent) {
    this.isDrawing = false;
  }

  // Check if the touch event is on the canvas
  private isTouchOnCanvas(e: TouchEvent): boolean {
    const bounds = this.sigPadElement.getBoundingClientRect();
    const touch = e.touches[0];
    return (
      touch.clientX >= bounds.left &&
      touch.clientX <= bounds.right &&
      touch.clientY >= bounds.top &&
      touch.clientY <= bounds.bottom
    );
  }

  private relativeCoords(event: MouseEvent) {
    const bounds = this.sigPadElement.getBoundingClientRect();
    const x = event.clientX - bounds.left;
    const y = event.clientY - bounds.top;
    return { x, y };
  }

  private relativeCoordsTouch(event: TouchEvent) {
    const bounds = this.sigPadElement.getBoundingClientRect();
    const x = event.touches[0].clientX - bounds.left;
    const y = event.touches[0].clientY - bounds.top;
    return { x, y };
  }

  clear() {
    this.context.clearRect(
      0,
      0,
      this.sigPadElement.width,
      this.sigPadElement.height
    );
    this.context.beginPath();
    this.isDrawing = false; // Reset drawing state
  }

  save() {
    this.img = this.sigPadElement.toDataURL('image/png');
    this.signSubmitted?.emit(this.img);
  }
}
