import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AuthService } from 'src/app/authentication/services/auth.service';
import { ClaimService } from 'src/app/services/claim.service';
import { environment } from 'src/environments/environment';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { HttpClient } from '@angular/common/http';
@Component({
  selector: 'tt-owner-request-form-view',
  templateUrl: './owner-request-form-view.component.html',
  styleUrls: ['./owner-request-form-view.component.scss'],
})
export class OwnerRequestFormViewComponent implements OnInit {
  @Input() selectedClaim;
  isUploading: boolean = false;

  statusOptions = [
    'pending',
    'requested',
    'reviewed',
    'processed',
    'completed',
    'deleted',
    'hold',
  ];

  signedImg;

  user: any;
  @Output()
  onClose = new EventEmitter();

  @Output()
  statusUpdateClicked = new EventEmitter();

  uploadFile: File | null = null;
  eSignRequest: boolean = false;
  viewDigitalDocs: boolean = false;

  eSignEnabled = false;
  isDesktop: boolean = false;

  constructor(
    private claimService: ClaimService,
    private authUser: AuthService,
    private breakpointObserver: BreakpointObserver,
    private http: HttpClient
  ) {}

  ngOnInit() {
    this.authUser.user$.subscribe((user) => (this.user = user));
    this.eSignEnabled = environment?.eSignEnabled;
    this.breakpointObserver.observe([Breakpoints.Web]).subscribe((result) => {
      this.isDesktop = result.matches;
    });
  }

  signSubmittedBtnClicked(img) {
    if (img) {
      this.signedImg = img;
      setTimeout(() => this.printContents(), 200);
    }
  }

  onEsignRequest() {
    this.eSignRequest = true;
  }
  onUploadSignedDocument(event: any) {
    this.uploadFile = event.target.files[0];
  }

  printUnsignedContents() {
    this.eSignRequest = true;
    setTimeout(() => this.printContents(), 200);
  }
  printContents() {
    const printContent = document.getElementById('print-section')?.innerHTML;
    const popupWin = window.open(
      '',
      '_blank',
      'top=0,left=0,height=100%,width=auto'
    );

    if (printContent && popupWin) {
      popupWin.document.open();
      popupWin.document.write(`
      <html>
        <head>
          <title>${this.selectedClaim?.flatNumber} - Signed Documents</title>
          <link rel="stylesheet" href="/assets/css/bootstrap.min.css">
          <link rel="stylesheet" href="/assets/css/print.css">
        </head>
        <body>
          ${printContent}
          <script>
            // Delay closing the window to ensure the print dialog appears
            window.onload = function() {
              window.print();
              setTimeout(function() {
                ${this.isDesktop ? 'window.close();' : ''}
              }, 500);  // Adjust delay as needed (500ms)
            };
          </script>
        </body>
      </html>
    `);
      popupWin.document.close();
    } else {
      console.error(
        "Element with ID 'print-section' not found or popup could not be opened."
      );
    }
  }

  onUploadFile(event: any) {
    this.isUploading = true;
    const clientId = environment.clientId;
    const unit = this.selectedClaim?.flatNumber;
    const userId = this.selectedClaim?.uid;
    const folderPath = `/${clientId}/${unit}/${userId}/${this.selectedClaim.id}`;
    this.claimService.uploadSignedDocument(folderPath, this.uploadFile).then(
      (downloadURL) => {
        this.isUploading = false;
        this.selectedClaim.signedDocUrl = downloadURL;
        this.claimService.updateOwnerData({
          id: this.selectedClaim.id,
          signedDocUrl: downloadURL,
          status: 'completed',
          modifiedDate: new Date().getTime(),
        });
      },
      (error) => {
        console.error('Document upload failed', error);
        this.isUploading = false;
      }
    );
  }

  onDeleteSignedDocument() {
    delete this.selectedClaim?.signedDocUrl;
    this.selectedClaim.status = 'reviewed';
  }

  onCloseBtnClicked() {
    this.onClose.emit();
  }

  onUpdateBtnClicked() {
    this.statusUpdateClicked.emit(this.selectedClaim);
  }

  // Delete the claim
  onDeleteRequest() {
    if (confirm('Are you sure you want to delete this request?')) {
      this.claimService.deleteClaim(this.selectedClaim.id).then(
        () => {
          this.selectedClaim = null; // Clear the detailed view
        },
        (error) => {
          console.error('Failed to delete request', error);
        }
      );
    }
  }
}
