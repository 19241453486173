export const environment = {
  production: true,
  path: "/ncltclaims/tafowa",
  clientId: "tafowa",
  baseUrl: "https://claim.tafowa.com/api",
  eSignEnabled: true,
  firebaseConfig: {
    apiKey: "AIzaSyCxL3GlYQNUPW7Q5H9SwsRG7FW2h-4C170",
    authDomain: "claim.tafowa.com",
    projectId: "nclt-claims",
    storageBucket: "nclt-claims.appspot.com",
    messagingSenderId: "644799185832",
    appId: "1:644799185832:web:6f73930da0ea92935c939d",
    measurementId: "G-KSDGTSZ785",
  },
};
