import { Component } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as XLSX from 'xlsx';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-admin-dashboard',
  templateUrl: './admin-dashboard.component.html',
  styleUrls: ['./admin-dashboard.component.scss'],
})
export class AdminDashboardComponent {
  // Variable to store parsed buyer data
  buyerData: any[] = [];

  constructor(private http: HttpClient) {}

  // Method to sync data by calling the /api/sync endpoint
  syncData() {
    const apiUrl = `${environment.baseUrl}/buyer/sync`;
    this.http.get(apiUrl).subscribe(
      (response) => {
        alert('Data synced successfully!');
      },
      (error) => {
        alert('Failed to sync data. Please try again later.');
      }
    );
  }

  // Method to handle file change (file selection)
  onFileChange(event: any) {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();

      reader.onload = (e: any) => {
        const binaryString = e.target.result;
        const workbook = XLSX.read(binaryString, { type: 'binary' });
        const sheet = workbook.Sheets[workbook.SheetNames[0]];
        this.buyerData = XLSX.utils.sheet_to_json(sheet, { header: 1 });
      };

      reader.readAsBinaryString(file);
    }
  }

  // Method to upload parsed buyer data
  uploadBuyerData() {
    if (this.buyerData && this.buyerData.length > 0) {
      const apiUrl = `${environment.baseUrl}/buyer/upload`;
      this.http.post(apiUrl, { data: this.buyerData }).subscribe(
        (response) => {
          alert('Buyer data uploaded successfully!');
        },
        (error) => {
          alert('Failed to upload buyer data. Please try again later.');
        }
      );
    } else {
      alert('No data to upload. Please ensure the file is correct.');
    }
  }
}
