<div class="container">
  <h2>Admin Dashboard</h2>
  <mat-card class="m-2">
    <h2>Sync Buyer Details With Claim</h2>
    <button (click)="syncData()" mat-flat-button color="primary">
      Sync Data
    </button>
  </mat-card>
  <mat-card class="m-2">
    <h2>Upload Buyer Details</h2>
    <p></p>
    <div class="file-upload-container">
      <input
        type="file"
        accept=".xlsx,.xls"
        (change)="onFileChange($event)"
        class="file-input"
      />
      <button
        (click)="uploadBuyerData()"
        mat-flat-button
        color="warn"
        [disabled]="!buyerData"
      >
        Upload Buyer Data
      </button>
    </div>
  </mat-card>
</div>
