import {
  Component,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { Title } from '@angular/platform-browser';
import { Observable, Subscription } from 'rxjs';
import { AuthService } from './authentication/services/auth.service';
import { CommonUtil } from './shared/common.util';
import { User } from './shared/models/user';
import { BrandingConfigService } from './shared/services/branding.service';
import { NgwWowService } from 'ngx-wow';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  title: string;
  appname: string;
  user: User;
  private wowSubscription: Subscription;

  @ViewChild('sidenav') public sidenav: MatSidenav;
  isHandset$: Observable<boolean>;

  constructor(
    commonUtil: CommonUtil,
    private titleService: Title,
    private authService: AuthService,
    private brandingConfig: BrandingConfigService,
    private wowService: NgwWowService,
    private renderer: Renderer2
  ) {
    this.isHandset$ = commonUtil.isHandset$;
    this.wowService.init();
  }
  ngOnDestroy(): void {
    this.wowSubscription.unsubscribe();
  }

  closeSideNav() {
    if (this.sidenav.mode === 'over') {
      this.sidenav.close();
    }
  }

  onLogout(user: User) {
    this.authService.logout();
  }

  ngOnInit(): void {
    this.wowSubscription = this.wowService.itemRevealed$.subscribe();
    this.titleService.setTitle(this.brandingConfig.getConfig('title'));
    this.appname = this.brandingConfig.getConfig('appname');
    this.authService.user$.subscribe((data) => (this.user = data));
  }
}
