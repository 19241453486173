import { Injectable } from '@angular/core';
import {
  AngularFirestore,
  AngularFirestoreCollection,
} from '@angular/fire/compat/firestore';
import { User } from '../models/user';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private userStoreRef: AngularFirestoreCollection<any>;
  getAllUser() {
    return this.userStoreRef.valueChanges();
  }

  checkUserRole(uid: string) {
    const userPath = `${environment?.path}/admins`;
    return this.store
      .collection(userPath)
      .doc(uid)
      .ref.get()
      .then((doc) => {
        if (doc.exists) {
          return doc.data();
        }
      });
  }

  saveUser(user: User) {
    return this.userStoreRef.add(user);
  }

  getUser(uid: string) {
    return this.userStoreRef.doc(uid).get();
  }

  constructor(private store: AngularFirestore) {
    const userPath = `${environment.path}/users`;
    this.userStoreRef = store.collection(userPath);
  }
}
