import { DatePipe } from '@angular/common';
import { Component } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from 'src/app/authentication/services/auth.service';
import { ClaimService } from 'src/app/services/claim.service';
import { environment } from 'src/environments/environment';
import * as XLSX from 'xlsx';

@Component({
  selector: 'tt-apply-claim',
  templateUrl: './apply-claim.component.html',
  styleUrls: ['./apply-claim.component.scss'],
})
export class ApplyClaimComponent {
  ownerForm: FormGroup;
  user: any;
  aadharFile: File | null = null;
  aadharFileError: string | null = null;
  formSubmitted: boolean = false;
  isSubmitting = false;
  claims: any[] = [];
  selectedClaim: any;
  newResponseForm: boolean = false;
  statusCounts: any;

  filteredClaims: any[] = [];
  constructor(
    private fb: FormBuilder,
    private authUser: AuthService,
    private firestore: AngularFirestore,
    private claimService: ClaimService,
    private pipe: DatePipe
  ) {
    this.authUser.user$.subscribe((user) => {
      this.user = user;
      if (this.user?.uid)
        this.claimService
          .getOwnerDataByUserId(this.user?.uid, this.user?.admin)
          .subscribe((claims) => {
            this.claims = claims;
            if (this.user?.admin) {
              this.filteredClaims = claims.filter(
                (x) =>
                  ['deleted', 'reviewed', 'completed'].includes(x.status) !=
                  true
              );
              this.statusCounts = this.countClaimsByStatus(claims);
            } else {
              this.filteredClaims = claims;
              this.statusCounts = undefined;
            }
          });
    });
    this.ownerForm = this.fb.group({
      ownerName: ['', Validators.required],
      flatNumber: [
        '',
        [Validators.required, Validators.pattern(/^[A-Z]\d{3}$/)],
      ],
      mobileNumber: [
        '',
        [Validators.required, Validators.pattern('[0-9]{10}')],
      ],
      email: [this.user?.email, [Validators.required, Validators.email]],
      accountNumber: ['', Validators.required],
      bankName: ['', Validators.required],
      purchaseType: ['', Validators.required],
      aadharNumber: [
        '',
        [Validators.required, Validators.pattern('^[0-9]{12}$')],
      ],
      ifscCode: [
        '',
        [Validators.required, Validators.pattern(/^[A-Z]{4}0[A-Z0-9]{6}$/)],
      ],
      branch: ['', Validators.required],
      accountHolderName: ['', Validators.required],
      consent: [false, Validators.requiredTrue],
    });
  }
  handleViewClaimDetails(claim) {
    this.selectedClaim = claim;
  }
  countClaimsByStatus = (claims) => {
    return claims.reduce((count, claim) => {
      count[claim.status] = (count[claim.status] || 0) + 1;
      return count;
    }, {});
  };

  selectedFlatNumber: any;
  searchBtnClick() {
    if (this.selectedFlatNumber) {
      this.selectedClaim = this.claims.find(
        (x) => x.flatNumber === this.selectedFlatNumber
      );
    }
  }

  isEligibleToDisplay() {
    return (
      !this.selectedClaim &&
      ((this.claims.length > 0 && this.newResponseForm) ||
        this.claims.length == 0)
    );
  }

  handleStatusUpdate(claim) {
    const clientId = environment.clientId;
    const updatedClaim: any = {
      id: claim?.id,
      status: claim?.status,
    };
    if (claim?.comments) {
      updatedClaim.comments = claim?.comments;
    }
    this.claimService.updateOwnerData(updatedClaim);
    this.selectedClaim = null;
  }

  closedSelectedClaimView() {
    this.selectedClaim = undefined;
  }

  displayForm() {
    this.selectedClaim = undefined;
    this.onFormReset();
    this.newResponseForm = true;
  }

  onFileChange(event: any) {
    const file = event.target.files[0];
    this.aadharFileError = null; // Reset the error message

    if (file) {
      const fileExtension = file.name.split('.').pop().toLowerCase();
      // Check file type (only allow PDF or image formats)
      if (
        fileExtension !== 'pdf' &&
        !['jpg', 'jpeg', 'png'].includes(fileExtension)
      ) {
        this.aadharFileError =
          'Please upload a valid PDF or image file (JPG, JPEG, PNG).';
        this.aadharFile = null; // Reset file
      } else {
        this.aadharFile = file; // Save the file
      }
    } else {
      this.aadharFile = null; // Reset file if no file is selected
    }
  }

  clearFile() {
    this.aadharFile = null; // Clear the file selection
    this.aadharFileError = null; // Reset any error message
    const fileInput = document.getElementById('fileInput') as HTMLInputElement;
    if (fileInput) {
      fileInput.value = ''; // Clear the input field
    }
  }
  successMessage: string;
  errorMessage: string;

  cols = [
    'id',
    'flatNumber',
    'ownerName',
    'mobileNumber',
    'email',
    'purchaseType',
    'aadharNumber',
    'claimAmount',
    'saleableArea',
    'accountNumber',
    'accountHolderName',
    'ifscCode',
    'bankName',
    'branch',
    'aadharFile',
    'signedDocUrl',
    'association',
    'status',
    'comments',
    'createdDate',
    'modifiedDate',
  ];
  async onSubmit() {
    if (this.ownerForm.valid && this.aadharFile) {
      this.isSubmitting = true;
      this.errorMessage = '';
      this.successMessage = '';
      try {
        const clientId = environment.clientId;
        const unit = this.ownerForm.value?.flatNumber;
        const userId = this.user?.uid;
        const docId = this.firestore.createId();

        const folderPath = `/${clientId}/${unit}/${userId}/${docId}`;
        // File upload
        const downloadURL = await this.claimService.uploadAadharFile(
          folderPath,
          this.aadharFile
        );

        // Prepare form data
        const formData = {
          ...this.ownerForm.value,
          uid: userId,
          aadharFile: downloadURL,
          createdDate: new Date().getTime(),
          id: docId,
          status: 'requested',
        };

        // Save form data
        await this.claimService.createOwnerData(formData);
        // Show success message
        this.successMessage = 'Your data has been successfully submitted!';
        this.formSubmitted = true;
      } catch (error) {
        console.error('Error during form submission:', error);
        this.errorMessage =
          'An error occurred while submitting your data. Please try again or contact the assoication EC members';
      } finally {
        this.isSubmitting = false;
      }
    } else {
      // Handle validation errors
      this.isSubmitting = false;
      this.errorMessage =
        'Please ensure all required fields are filled out and the Aadhar file is uploaded.';
    }
  }

  async exportExcel() {
    const fileName = 'export-claims-' + new Date().getTime();
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(
      JSON.parse(
        JSON.stringify(
          this.claims.map((x) => {
            if (x.createdDate) {
              x.createdDate = this.pipe.transform(
                new Date(x.createdDate),
                'shortDate'
              );
            }
            if (x.modifiedDate) {
              x.modifiedDate = this.pipe.transform(
                new Date(x.modifiedDate),
                'shortDate'
              );
            }
            const item = {};
            this.cols.forEach((key) => {
              item[key.toUpperCase()] = x[key];
            });
            return item;
          })
        )
      )
    );
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Claims');
    XLSX.writeFile(wb, fileName + '.xlsx');
  }

  onFormReset() {
    this.formSubmitted = false;
    this.ownerForm.reset();
    this.aadharFile = null;
    this.aadharFileError = '';
    if (!this.user?.admin) {
      this.ownerForm.patchValue({
        email: this.user?.email,
      });
    }
  }
}
