<div class="container" style="max-width: 400px">
  <div class="m-3 mt-10 d-flex flex-column align-content-center">
    <mat-toolbar class="m-0 p-0" color="accent">
      <div class="title-center">Login</div>
    </mat-toolbar>
    <mat-card class="mat-elevation-z10 p-0">
      <firebase-ui
        (signInSuccessWithAuthResult)="successCallback($event)"
      ></firebase-ui>
    </mat-card>
  </div>
</div>
