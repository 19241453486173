<div class="container my-3">
  <!-- Profile Toolbar -->
  <mat-toolbar color="primary" class="d-flex justify-content-center">
    <span class="mat-headline-6">Profile</span>
  </mat-toolbar>

  <!-- Profile Card -->
  <mat-card class="mt-4 p-4">
    <div class="row">
      <!-- User Image Section -->
      <div
        class="col-12 d-flex justify-content-center align-items-center mb-4 mb-md-0"
      >
        <img
          class="rounded-circle img-fluid"
          style="max-height: 150px; width: 150px"
          [src]="user.photoURL"
          alt="Profile Picture"
        />
      </div>

      <!-- User Details Section -->
      <div class="col-12">
        <table class="table table-borderless w-100">
          <tbody>
            <tr>
              <td class="font-weight-bold">ID</td>
              <td>{{ user.uid }}</td>
            </tr>
            <tr>
              <td class="font-weight-bold">Name</td>
              <td>{{ user.displayName }}</td>
            </tr>
            <tr *ngIf="user.email">
              <td class="font-weight-bold">Email</td>
              <td>{{ user.email }}</td>
            </tr>
            <tr *ngIf="user.phoneNumber">
              <td class="font-weight-bold">Mobile</td>
              <td>{{ user.phoneNumber }}</td>
            </tr>
            <tr *ngIf="user.admin">
              <td class="font-weight-bold">User Type</td>
              <td>Administrators</td>
            </tr>
            <tr>
              <td class="font-weight-bold">Created Time</td>
              <td>{{ getDate(user.creationTime) | date : "short" }}</td>
            </tr>
            <tr>
              <td class="font-weight-bold">Last Login Time</td>
              <td>{{ getDate(user.lastSignInTime) | date : "short" }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </mat-card>
</div>
