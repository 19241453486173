import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/authentication/services/auth.service';
import { User } from 'src/app/shared/models/user';

@Component({
  selector: 'tt-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit {
  user: User;
  constructor(private authService: AuthService) {}

  getDate(date) {
    return Date.parse(date);
  }

  ngOnInit(): void {
    this.authService.user$.subscribe((data) => {
      this.user = data;
    });
  }
}
