<div id="print-section">
  <div>
    <div class="m-3">
      <h3 class="text-center"><strong> FORM CA</strong></h3>
      <h3 class="text-center">
        SUBMISSION OF CLAIM BY FINANCIAL CREDITORS IN A CLASS
      </h3>
      <p class="text-center">
        (Under Regulation 8A of the Insolvency and Bankruptcy (Insolvency
        Resolution Process for Corporate Persons) Regulations, 2016)
      </p>

      <div class="text-end">
        <p><strong>Date:</strong> {{ claim?.createdDate | date }}</p>
      </div>
      <div class="px-2">
        <p>
          <strong>From</strong><br />
          {{ claim?.ownerName }}<br />
          {{ claim?.flatNumber }}<br />
          Amar Prakash Temple Waves, <br />PKV Maha Nagar Road Extn.,<br />
          Kundrathur, Tamil Nadu Chennai – 600069
          <br />
          {{ claim?.email }}
        </p>

        <p>
          <strong>To</strong><br />
          TruPro Insolvency Services LLP <br />Resolution Professional of M/s
          Amar Prakaash Developerss Pvt. Ltd. <br />
          No. 581, Third Floor (Top Floor),<br />
          Sector 27, Gurugram-122001
        </p>

        <h4>Subject: Submission of claim and proof of claim.</h4>
        <p>Madam/Sir,</p>
        <p>
          {{ claim?.ownerName | uppercase }}, hereby submits this claim in
          respect of the corporate insolvency resolution process of M/s Amar
          Prakaash Developerss Pvt. Ltd.The details for the same are set out
          below:
        </p>
      </div>
      <table
        class="table table-bordered"
        border="1"
        cellpadding="5"
        cellspacing="0"
      >
        <tr class="text-center">
          <td colspan="3">RELEVANT PARTICULARS</td>
        </tr>
        <tr>
          <td>1.</td>
          <td>Name of the financial creditor</td>
          <td>{{ claim?.ownerName }}</td>
        </tr>
        <tr>
          <td>2.</td>
          <td>
            Identification number of the financial creditor<br />
            (If an incorporated body, provide identification number and proof of
            incorporation. If a partnership or individual, provide
            identification records of all the partners or the individual)
          </td>
          <td>{{ claim?.aadharNumber }}</td>
        </tr>
        <tr>
          <td>3.</td>
          <td>
            Address and e-mail address of the financial creditor for
            correspondence.
          </td>
          <td>
            {{ claim?.flatNumber }}<br />
            Amar Prakash Temple Waves, <br />PKV Maha Nagar Road Extn.,<br />
            Kundrathur, Tamil Nadu Chennai – 600069
            <br />
            {{ claim?.email }}
          </td>
        </tr>
        <tr>
          <td>4.</td>
          <td>Total amount of claim (in Rs.)</td>
          <td>
            Rs. {{ claim?.claimAmount }}<br />
            Interest has been claimed at the rate of 8% per annum in terms of
            Regulation 7 of Insolvency and Bankruptcy of India (insolvency of
            Corporate Persons) Regulations, 2016. Computation for claimed amount
            along with receipts of payment.
          </td>
        </tr>
        <tr>
          <td>5.</td>
          <td>
            Details of documents by reference to which the debt can be
            substantiated
          </td>
          <td>
            The debt can be substantiated with reference to receipts for payment
            issued by the Company.
          </td>
        </tr>
        <tr>
          <td>6.</td>
          <td>Details of how and when debt incurred</td>
          <td>
            The debt was incurred on the date of payments to the Corporate
            Debtor in terms of the following agreements are "Agreement of
            Project Promotion and Construction – (APPAC)"
          </td>
        </tr>
        <tr>
          <td>7.</td>
          <td>
            Details of any mutual credit, mutual debts, or other mutual dealings
            between the corporate debtor and the creditor which may be set-off
            against the claim
          </td>
          <td>NIL</td>
        </tr>
        <tr>
          <td>8.</td>
          <td>
            Details of any security held, the value of the security, and the
            date it was given
          </td>
          <td>
            Details of security: <br />Project:Temple Waves Apartment
            <br />No:{{ claim?.flatNumber }} Saleable Area:{{
              claim?.saleableArea
            }}
            SQ.FT
            <br />
            The security was created on the date of execution of Agreement of
            Project Promotion and Construction.
          </td>
        </tr>
        <tr>
          <td>9.</td>
          <td>
            Details of the bank account to which the amount of the claim or any
            part thereof can be transferred pursuant to a resolution plan
          </td>
          <td>
            {{ claim?.accountHolderName }} <br />
            {{ claim?.accountNumber }} <br />
            {{ claim?.ifscCode }} <br />
            {{ claim?.bankName }} <br />
            {{ claim?.branch }} <br />
          </td>
        </tr>
        <tr>
          <td>10.</td>
          <td>
            List of documents attached to this claim in order to prove the
            existence and non-payment of claim due
          </td>
          <td>APPAC , Payment Receipts Summary and claim Details</td>
        </tr>
        <tr>
          <td>11.</td>
          <td>
            Name of the insolvency professional who will act as the Authorised
            representative of creditors of the class
          </td>
          <td>Mr. RAJAGOPALAN SANKARAN</td>
        </tr>
        <tr>
          <td colspan="3" class="text-center">
            <p>
              For <strong>{{ claim?.ownerName }}</strong>
            </p>
            <div style="margin-top: 100px">
              <p>Authorized signatory</p>
            </div>
            <p>
              Signature of financial creditor or person authorised to act on its
              behalf<br />
              [Please enclose the authority if this is being submitted on behalf
              of the financial creditor]
            </p>
          </td>
        </tr>
        <tr>
          <td colspan="3">
            <p>Name in BLOCK LETTERS:{{ claim?.ownerName }}</p>
          </td>
        </tr>
        <tr>
          <td colspan="3">
            <p>Position with or in relation to creditor : SELF</p>
          </td>
        </tr>
        <tr>
          <td colspan="3">
            <p>
              Address of homebuyer :

              {{ claim?.flatNumber }} Amar Prakash Temple Waves, <br />PKV Maha
              Nagar Road Extn., Kundrathur, Tamil Nadu Chennai – 600069
            </p>
          </td>
        </tr>
      </table>
      <p>
        *PAN number, passport, AADHAAR Card or the identity card issued by the
        Election Commission of India.
      </p>
      <div class="full-page-section">
        <p><strong>DECLARATION</strong></p>
        <p>
          I, {{ claim?.ownerName }}, currently residing at
          {{ claim?.flatNumber }} Amar Prakash Temple Waves,PKV Maha Nagar Road
          Extn., Kundrathur, Tamil Nadu Chennai – 600069, do hereby declare and
          state as follows:
        </p>
        <ol>
          <li>
            M/s Amar Prakaash Developerss Pvt. Ltd, the corporate debtor was, at
            the insolvency commencement date, being the 18 day of April 2023,
            actually indebted to me for a sum of Rs. {{ claim?.claimAmount }}.
          </li>
          <li>
            In respect of my claim of the said sum or any part thereof, I have
            relied on the documents specified below: APPAC – Agrement of Project
            Promotion and Construction
          </li>
          <li>
            The said documents are true, valid and genuine to the best of my
            knowledge, information and belief and no material facts have been
            concealed therefrom.
          </li>
          <li>
            In respect of the said sum or any part thereof, neither I, nor any
            person, by my order, to my knowledge or belief, for my use, had or
            received any manner of satisfaction or security whatsoever, save and
            except the following: NIL
          </li>
          <li>
            I am / I am not a related party of the corporate debtor, as defined
            under section 5 (24) of the Code.
          </li>
          <li>
            I am eligible to give voting instruction to the authorized
            representative by virtue of proviso to section 21 (2) of the Code
            even though I am a related party of the corporate debtor.
          </li>
        </ol>

        <div class="row">
          <div class="col-6">
            <p class="text-start mt-5">
              <strong>Date:</strong> {{ claim?.createdDate | date }}<br />
              <strong>Place:</strong> Chennai
            </p>
          </div>
          <div class="col-6">
            <div class="d-flex flex-column align-items-end">
              <ng-container *ngTemplateOutlet="signImgView"></ng-container>
              <strong>(Signature of the Homebuyer)</strong>
            </div>
          </div>
          <div class="col-12 text-center">
            <p class="pt-2"><strong>VERIFICATION</strong></p>
          </div>
        </div>

        <p>
          I, {{ claim?.ownerName }} the Homebuyer hereinabove, do hereby verify
          that the contents of this proof of claim are true and correct to my
          knowledge and belief and no material fact has been concealed
          therefrom.
        </p>

        <p>Verified at Chennai on this {{ claim?.createdDate | date }}</p>

        <div class="d-flex flex-column align-items-end">
          <ng-container *ngTemplateOutlet="signImgView"></ng-container>
          <strong>(Signature of the Homebuyer)</strong>
        </div>

        <p>
          <em
            >[Note: In the case of company or limited liability partnership, the
            declaration and verification shall be made by the
            director/manager/secretary/designated partner and in the case of
            other entities, an officer authorized for the purpose by the
            entity.]</em
          >
        </p>
      </div>
    </div>
  </div>
  <div class="pagebreak"></div>
  <div>
    <div class="m-3">
      <div class="row">
        <div class="col-12">
          <p class="text-end">Date: {{ claim?.createdDate | date }} <br /></p>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <p class="text-start mt-3">
            From, <br />
            {{ claim.ownerName }},<br />
            {{ claim.flatNumber }},<br />
            Amarprakash Temple Waves,<br />
            PKV Maha Nagar Road Extn., <br />
            Kundrathur, Chennai-600069<br />
            {{ claim.email }}
          </p>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <p class="text-start mt-3">
            To, <br />
            {{ claim?.association }} <br />
            Amarprakash Temple Waves,<br />
            PKV Maha Nagar Road Extn., <br />
            Kundrathur, Chennai-600069<br />
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <p>
            Reference: Project: Temple Waves Unit No. {{ claim.flatNumber }}
          </p>
          <p>
            Subject: Authorization for submission of the claim form with the
            Resolution Professional and filing of the application before
            judicial authorities for safeguarding of my / our interest.
          </p>

          <p>Dear Sir,</p>
          <p>
            This has reference to my / our claim in the matter of Amar Prakaash
            Developers Private Limited - a company in liquidation
            (&ldquo;Corporate Debtor&rdquo;) for Rs. {{ claim.claimAmount }} /-
            being the Allottee of the above-mentioned apartment. I/ We have
            handed over to you duly filled claim Form CA along with proof of the
            claim amount.
          </p>
          <p>
            I/We authorize you for submission of the claim form on my/ our
            behalf with the resolution professional of the Corporate Debtor in
            electronic form or any other form / manner in terms of the
            Regulation 8A of Insolvency and Bankruptcy Board of India
            (Insolvency resolution process for corporate persons) Regulations,
            2016.
          </p>
          <p>
            I/We also authorize you for filing of the application before
            Hon&rsquo;ble National Company Law Tribunal and any other judicial
            forum for seeking suitable direction to the Resolution Professional
            for acceptance of the claim under the category of financial
            creditor, place the accepted claims before Committee of creditors
            for inclusion in the list of creditors and an appropriate treatment
            in the resolution plan for protection of my / our interest. I/We
            also authorize you take the necessary steps / actions, as may be
            necessary, on my / our behalf including for the engagement of and
            for issuance of instructions to the lawyer.
          </p>
          <div class="d-flex flex-column align-items-end">
            <ng-container *ngTemplateOutlet="signImgView"></ng-container>
            <p>Yours Faithfully<br /></p>
            <p>{{ claim.ownerName }}<br /></p>
            <p>{{ claim.aadharNumber }}<br /></p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <ng-template #signImgView>
    <img class="mt-5" [src]="signedImg" />
  </ng-template>

  <div *ngIf="!user?.admin">
    <app-sign-in-pad
      (signSubmitted)="signSubmitted($event)"
      [name]="claim.ownerName"
    ></app-sign-in-pad>
  </div>
</div>
