import { HttpClient } from '@angular/common/http';
import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/authentication/services/auth.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'tt-claims-table',
  templateUrl: './claims-table.component.html',
  styleUrls: ['./claims-table.component.scss'],
})
export class ClaimsTableComponent {
  user: any;
  eSignEnabled = false;
  @Input() data: {
    id: string;
    ownerName: string;
    flatNumber: string;
    mobileNumber: string;
    status?: string;
    comments?: string;
  }[];
  @Output() viewClaimDetails = new EventEmitter<string>();
  @Output() statusUpadateClick = new EventEmitter<string>();
  constructor(private authUser: AuthService, private http: HttpClient) {
    this.authUser.user$.subscribe((user) => (this.user = user));
    this.eSignEnabled = environment.eSignEnabled;
  }

  onViewClick(claim: any) {
    this.viewClaimDetails.emit(claim);
  }
  onHoldClick(claim: any) {
    this.statusUpadateClick.emit({
      ...claim,
      status: 'hold',
    });
  }

  onApproveClick(claim: any) {
    this.statusUpadateClick.emit({
      ...claim,
      status: 'reviewed',
    });
  }
  errorMessage: string | null = null;
  onDownload(claim: any) {
    this.errorMessage = '';
    const requestId = claim?.id;
    const downloadUrl = `${environment.baseUrl}/download/${requestId}`;
    this.http.get(downloadUrl, { responseType: 'json' }).subscribe({
      next: (response: any) => {
        if (response.downloadUrl) {
          window.open(response.downloadUrl, '_blank');
        } else {
          console.error('Download URL not found in the response');
        }
      },
      error: (error) => {
        console.error('Error downloading file:', error);
        this.errorMessage =
          'Failed to download the file. Please try again later.';
      },
    });
  }
}
