import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { User } from '../../shared/models/user';
import { switchMap, catchError, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private authService: AuthService) {}

  canActivate(): Observable<boolean> {
    const user = this.authService.user;
    if (user && user.uid) {
      return of(true);
    } else {
      this.router.navigateByUrl('/login');
      return of(false);
    }
  }
}
