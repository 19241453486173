import { Component, OnInit, Renderer2 } from '@angular/core';
import { BrandingConfigService } from '../../services/branding.service';

@Component({
  selector: 'tt-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  copyright: any;
  year = new Date().getFullYear();
  constructor(
    private branding: BrandingConfigService,
    private renderer: Renderer2
  ) {}

  ngOnInit(): void {
    this.copyright = this.branding.getConfig('copyright');
    this.loadGoogleTranslate();
  }

  private loadGoogleTranslate() {
    const script = this.renderer.createElement('script');
    script.type = 'text/javascript';
    script.src =
      'https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit';
    script.async = true;
    script.defer = true;
    this.renderer.appendChild(document.body, script);

    const googleTranslateInit = this.renderer.createElement('script');
    googleTranslateInit.type = 'text/javascript';
    googleTranslateInit.text = `
      function googleTranslateElementInit() {
        new google.translate.TranslateElement({
          pageLanguage: 'en',
          includedLanguages: 'en,hi,ta,ml,kn,te',
          autoDisplay: false,
        }, 'google_translate_element');
        
        // Hide the Google Translate banner and menu frame
        function hideGoogleTranslateElements() {
          const translateBanner = document.querySelector('.goog-te-banner-frame');
          if (translateBanner) {
            translateBanner.style.display = 'none';
          }
          const translateMenu = document.querySelector('.goog-te-menu-frame');
          document.body.style.top = '0px';
          if (translateMenu) {
            translateMenu.style.display = 'none';
          }
        }
        
        // Initial hide
        hideGoogleTranslateElements();

        // Hide elements on language change
        setInterval(hideGoogleTranslateElements, 500);
      }
    `;
    this.renderer.appendChild(document.body, googleTranslateInit);
  }
}
