import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class BrandingConfigService {
  private config: Object = null;
  constructor(private http: HttpClient) {}

  public getConfig(key: any) {
    return this.config[key];
  }

  public load() {
    return new Promise((resolve) => {
      this.http.get('/assets/json/config.json').subscribe((data) => {
        this.config = data;
        resolve(true);
      });
    });
  }
}
